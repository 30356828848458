export const API_STATUS = Object.freeze({
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    FULFILLED: 'Fulfilled',
    NETWORK_ERROR: 'Network Error'
})

export const ORDER_STATUS = Object.freeze({
    0: "ATTEMPTED",
    1: "PENDING",
    2: "CONFIRMED",
    3: "FAILED",
    4: "REJECTED"
})
export const PAY_METHOD = Object.freeze({
    ONLINE: 1,
    COD: 0
})