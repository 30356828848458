import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constant";
import { signIn, signUp } from "../../services/api";

const namespace = "signInConfiguration";

const initialState = {
    loading: "initial",
    errorMessage: "",
    signInData: null,
    signInLoading: "initial",
    signUpLoading: "initial",
    updateSchedulerLoading: "initial",
    count: 0,
    signUpData: []
};

export const signInScheduler = createAsyncThunk(
    `${namespace}/signInScheduler`,
    async ({ formData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(formData, "formData");
            const { data } = await signIn(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const signUpScheduler = createAsyncThunk(
    `${namespace}/signUpScheduler`,
    async ({ formData }, { rejectWithValue, dispatch }) => {
        console.log(formData,'signup reducer formdata')
        try {
            console.log(formData, "formData");
            const { data } = await signUp(formData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


const signInConfigSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearSignInData: () => {
            return initialState;
        },
        logOut: () => {
            localStorage.clear();
            window.location.reload(true);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signUpScheduler.pending, (state) => {
            state.signUpLoading = API_STATUS.PENDING;
        })
        builder.addCase(signUpScheduler.fulfilled, (state, { payload }) => {
            state.signUpLoading = API_STATUS.FULFILLED;
            state.signUpData = payload;
        })
        builder.addCase(signUpScheduler.rejected, (state, action) => {
            state.signUpLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(signInScheduler.pending, (state) => {
            state.signInLoading = API_STATUS.PENDING;
        });
        builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
            state.signInLoading = API_STATUS.FULFILLED;
            state.signInData = payload;
            console.log(payload);
            localStorage.setItem("authToken", payload?.accessToken);
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("username", payload?.username);
        });
        builder.addCase(signInScheduler.rejected, (state, action) => {
            state.signInLoading = API_STATUS.REJECTED;
            console.log(action);
            state.errorMessage = action?.payload?.data;
        });
    },
});

export const { clearSignInData, logOut } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
