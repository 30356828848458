import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './components/Layout/header';
import Footer from './components/Layout/footer';

console.log("inasdsad");
const PrivateRoute = () => {
    const isAuth = sessionStorage.getItem('isAuthenticated');
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    if (!isAuthenticated) {
        localStorage.clear();
    }
    return (
        isAuthenticated ? <>
            <div id="wrapper">
                {/* <Header /> */}
                <div id="main-content">
                    <Outlet />
                </div>
                {/* <Footer /> */}
            </div>
        </>
            : <Navigate to="/signin" />);
};
//(props) => (!isAuthenticated ? <Navigate to="/login" replace={true} /> : <Component {...props} />)


const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(PrivateRoute);