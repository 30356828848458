import { useEffect, useRef, useState } from "react";
import "../../assets/css/login.css"
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signInValidationSchema, signUpValidationSchema } from "../../utils/Validation";
import { signInScheduler, signInSelector, signUpScheduler,clearSignInData } from "../../store/reducer/SigninReducer";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils/Constant";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Logo from "../../assets/img/logo.png"


const Signup = () => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [isSignIn, setIsSignIn] = useState(true);
    const container = useRef("")
    const signInEmail = useRef("")
    const signUpEmail = useRef("")
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(isSignUp ? signUpValidationSchema : signInValidationSchema),
    });

    const { signInLoading, signUpLoading } = useSelector(signInSelector)

    const onSubmitHandler = formData => {
        const unwantedData = ['email1', 'password1', 'name']
        let keys = Object.keys(formData)
        keys.forEach((ele) => {
            if (formData[ele] === "" || formData[ele] === null) {
                delete formData[ele]
            }
        })
        // formData.email = formData.email1;
        // formData.password = formData.password1
        unwantedData.forEach((ele) => {
            delete formData[ele]
        })
        dispatch(signInScheduler({ formData }))
    }

    const onSubmit1Handler = formData => {
        const unwantedData = ['email1', 'password1']
        let keys = Object.keys(formData)
        keys.forEach((ele) => {
            if (formData[ele] === "" || formData[ele] === null) {
                delete formData[ele]
            }
        })
        formData.email = formData.email1;
        formData.password = formData.password1
        unwantedData.forEach((ele) => {
            delete formData[ele]
        })
        dispatch(signUpScheduler({ formData }))
    }


    useEffect(() => {
        if (signUpLoading === API_STATUS.FULFILLED) {
            reset();
            navigate('/signin');
            toast.success("User Created Successfully!");
            setIsSignUp(false)
            dispatch(clearSignInData());
        }
        if (signInLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Logged In Successfully!");
            dispatch(clearSignInData());
            reset();
            navigate('/');
        }
        // if (updateloading === API_STATUS.FULFILLED) {
        //   toastr.clear();
        //   toastr.success("Customer Updated Successfully!");
        //   setEditState((prevState) => ({
        //     edit_modal_fullscreen: !prevState.edit_modal_fullscreen,
        //   }));
        //   customerDispatch();
        //   dispatch(clearCustLoadingDatas());
        //   reset2();

        // }

        // if (deleteloading === API_STATUS.FULFILLED) {
        //   toastr.clear();
        //   toastr.success("Customer Deleted Successfully!");
        //   customerDispatch();
        //   dispatch(clearCustLoadingDatas());
        // }
        // if (errorMessage) {
        //   toastr.clear();
        //   if (errorMessage.message) toastr.error(errorMessage.message);
        //   else if (errorMessage.errors) {
        //     let data = "";
        //     errorMessage?.errors.map((err) => {
        //       data += err.message + " ";
        //     });
        //     toastr.error(data);
        //   }
        //   dispatch(clearErrormsg());
        // }
    }, [signInLoading, signUpLoading]);

    function openSignIn() {
        navigate('/signin')
        reset()
        // setIsSignUp(false)
        // setIsSignIn(true)
        // container.current.classList.remove("right-panel-active");
        // if (signUpEmail.value !== "") {
        //     signInEmail.value = signUpEmail.value;
        // }
    }

    function openSignUp() {
        reset()
        setIsSignUp(true)
        setIsSignIn(false)
        // container.current.classList.add("right-panel-active");
        if (signInEmail.value !== "") {
            signUpEmail.value = signInEmail.value;
        }
    }


    return (
        <>

            <div className="login">

                <div class="logo" onClick={() => navigate('/')}>
                    <img src={Logo} alt="" />
                </div>
                <div class="login_container" id="container" ref={container}>
                    <div class="form-container sign-in-container" style={{ display:  'block' }}>
                        <form onSubmit={handleSubmit(onSubmit1Handler)}>
                            <h1>Create Account</h1>
                            <span>or use your email for registration</span>
                            <input type="text" placeholder="Name"  {...register("name")} />
                            {errors?.name?.message && (
                                <p className="error">{errors.name.message}</p>
                            )}
                            {/* <input type="text" placeholder="Surname"  {...register("surname")} />
                        {errors?.surname?.message && (
                            <p className="error">{errors.surname.message}</p>
                        )} */}
                            <input type="email" id="signUpEmail" placeholder="Email" ref={signUpEmail} name="email1"   {...register("email1")} />
                            {errors?.email?.message && (
                                <p className="error">{errors.email.message}</p>
                            )}
                            <input type="password" placeholder="Password" name="password1"   {...register("password1")} />
                            {errors?.password?.message && (
                                <p className="error">{errors.password.message}</p>
                            )}
                            <button type="submit">Sign Up</button>
                        </form>
                    </div>

                    <div class="overlay-container">
                        <div class="overlay">
                            <div class="overlay-panel overlay-left">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button class="ghost" id="signIn" onClick={openSignIn}>Sign In</button>
                            </div>
                            <div class="overlay-panel overlay-right">
                                <h1>Welcome Back!</h1>
                                <p>To keep connected with us please login with your personal info</p>
                                <button class="ghost" id="signUp" onClick={openSignIn}>Login</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Signup;
