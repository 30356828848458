import React, { useEffect, useState } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CartSelector, getOrders } from '../store/reducer/CartReducer'
import dayjs from 'dayjs'
import { ORDER_STATUS } from '../utils/Constant'
import { API_BASE } from "../services/config";

const MyOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orderData } = useSelector(CartSelector)
    const [query, setQuery] = useState("")
    const [page, setPage] = useState(0);
    const [limit, setlimit] = useState(0)
    useEffect(() => {
        dispatch(getOrders({ query, limit, page }))
    }, [])
    console.log(orderData, 'orderData')
    return (
        <div>
            <Header />
            <section class="user_dashboard_pages">
                <div class="container">
                    <div class="row innerpage user_dashboard space-between align-flex-start">
                        <div class="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                            <div class="myaccounts_sidebar white_bg">
                                <div class="account_nav">
                                    <ul>
                                        <li>
                                            <Link to={'/my_account'} >My Account</Link>
                                        </li>
                                        <li>
                                            <Link to={'/my_orders'}>My Orders</Link>
                                        </li>
                                        <li><div style={{ cursor: 'pointer' }} onClick={() => {
                                            localStorage.clear();
                                            navigate('/');
                                        }}>Log Out</div></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-9 col-xl-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="dashboard_area white_bg">
                                <div class="col-md-12">
                                    <div class="myaccount_edit flex align-center">
                                        <div class="left">
                                            <h3 class="sub_heading">My Orders</h3>
                                        </div>
                                    </div>

                                    <div class="question_lists">
                                        {
                                            orderData &&
                                            orderData.map((orders) => {
                                                console.log(orders,'ORDERSSSS')
                                                return (
                                                    <div class="lists dflex">
                                                        <div className='res-layout'>
                                                            <div class="left">
                                                                <img src={API_BASE +orders?.products?.image} alt="" />
                                                            </div>
                                                            <div class="right">
                                                                <h4>{orders?.products?.name}</h4>
                                                                <span class="qty"><b>Order Quantity :</b> {orders?.quantity}</span>
                                                                <span class="date"><b>Order Date :</b>{dayjs(orders.updated_at).format('YYYY/MM/DD')}</span>
                                                            </div>
                                                        </div>
                                                        <a href={null} class="re-order">{ORDER_STATUS[orders.orders.order_status]}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default MyOrders