import React, { useState } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
import WELCOME from "../assets/img/aboutimg.jpg"
import BENEFIT from "../assets/img/benefit-1.jpg"
import BENEFIT2 from "../assets/img/benefit-2.jpg"
import BENEFIT3 from "../assets/img/benefit-3.jpg"
import BENEFIT4 from "../assets/img/benefit-4.jpg"

const About = () => {
    const [check, setCheck] = useState({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true
    });
    const handleCheck = id => {
        let f_data = {}
        const chk_data = Object.keys(check);
        for (let index = 0; index < chk_data.length; index++) {
            const element = chk_data[index];
            console.log(element, id, 'chk dataa')
            if (id == element) {
                f_data[element] = !check[element]
            } else {
                f_data[element] = true
            }
            setCheck(f_data)
        }
    };
    return (
        <div>
            <Header />
            <div>

                {/* <!-- HERO SECTION PART START --> */}
                <div class="hero_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="herosection_content">
                                    <h2>About</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- HERO SECTION PART END --> */}

                <section class="about">
                    <div class="container">
                        <div class="row align">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="about-content">
                                    <h3><b>Welcome to Art Gallery -</b><span style={{ color: "#60cd12" }}> Discover Your Perfect Piece of Art!</span></h3><br />
                                    <p><b>Connecting art lovers with unique, handpicked pieces from talented artists worldwide.</b></p><br />
                                    <p>We are dedicated to connecting art lovers with unique, handpicked pieces from talented artists worldwide. Our mission is to make art accessible to everyone, providing a platform for artists to showcase their work. Each piece is carefully selected to ensure the highest quality and authenticity. Join us in celebrating creativity and enriching your space with beautiful art.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="about-image">
                                    <img src={WELCOME} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </section>

                <section class="benefits">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    <img src={BENEFIT} alt='' />
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#004318" }}>
                                    <div class="card-body">
                                        <h3><b>Abstract Art:</b></h3>
                                        <p>Abstract paintings explore non-representational forms, colors, and textures, emphasizing creativity and imagination over realistic depictions. Artists experiment with shapes, lines, and compositions, often using bold colors and dynamic patterns to evoke emotions and ideas. These artworks invite viewers to interpret and engage with visual elements freely, encouraging personal introspection and exploration. Abstract paintings can range from minimalist expressions to complex narratives, challenging conventional perspectives and offering new ways of seeing and experiencing art. They embody artistic freedom and innovation, pushing boundaries and inspiring creativity across diverse audiences.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mobile-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#008849" }}>
                                    <div class="card-body">
                                        <h3><b>Landscape Painting:</b></h3>
                                        <p>Landscape paintings depict natural scenery such as mountains, rivers, forests, and skies. Artists capture the beauty of the outdoors, often emphasizing the changing light and seasons. These artworks evoke a sense of tranquility and awe, inviting viewers to connect with nature's grandeur. Techniques vary from realistic to impressionistic, each conveying a unique perspective and mood. Whether capturing a serene countryside or a bustling cityscape, landscape paintings transport viewers to different places and evoke emotional responses through color, composition, and brushwork.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    <img src={BENEFIT2} alt='' />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    <img src={BENEFIT3} alt='' />
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#12a05ed0" }}>
                                    <div class="card-body">
                                        <h3><b>Portrait Painting:</b></h3>
                                        <p>Portrait paintings focus on capturing the likeness and personality of an individual or group. Artists skillfully portray facial expressions, gestures, and emotions, offering insight into the subject's character and life. From formal commissioned portraits to intimate studies, these artworks provide glimpses into history, culture, and personal narratives. Techniques range from traditional realism to abstract interpretations, each conveying a distinct interpretation of the human form and spirit. Portrait paintings serve as visual records of people's lives, relationships, and identities, enduring as timeless reflections of humanity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mobile-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#66a87d" }}>
                                    <div class="card-body">
                                        <h3><b>Still Life Painting:</b></h3>
                                        <p>Still life paintings depict inanimate objects arranged in a composition, often focusing on everyday items like fruits, flowers, utensils, and household items. Artists meticulously render textures, light, and shadow, creating scenes that celebrate beauty in ordinary moments. These artworks showcase craftsmanship and attention to detail, inviting viewers to appreciate the nuances of color, form, and arrangement. Still life paintings can convey themes of abundance, transience, and the passage of time, offering reflections on human existence and the natural world. From traditional realism to modern interpretations, these artworks capture moments frozen in time, inviting contemplation and appreciation of life's simple pleasures.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    <img src={BENEFIT4} alt='' />
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                <section class="mission">
                    <div class="container">
                        <div class="row align">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="mission-left">
                                    <h2>Our Mission</h2>
                                    <p>Our mission is to democratize art by connecting artists with a global audience, offering curated collections that inspire and enrich lives. We strive to promote cultural diversity and creativity, supporting artists in sharing their unique perspectives through our platform. By fostering a community of art enthusiasts and collectors, we aim to make art accessible, meaningful, and transformative for everyone.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="mission-right">
                                    <ul>
                                        <li>
                                            <input type="checkbox" checked={check['1']} onChange={() => handleCheck(1)} />
                                            <i></i>
                                            <h2>Discover Unique Artworks</h2>
                                            <p>Explore a curated selection of unique artworks from talented artists worldwide. Each piece is carefully chosen to offer something special for every art enthusiast.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['2']} onChange={() => handleCheck(2)} />
                                            <i></i>
                                            <h2>Shop Securely with Confidence</h2>
                                            <p>Enjoy peace of mind with our secure payment options and encrypted transactions. Your privacy and financial security are our top priorities.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['3']} onChange={() => handleCheck(3)} />
                                            <i></i>
                                            <h2>Global Art, Local Delivery</h2>
                                            <p>We ship artworks worldwide, ensuring safe delivery to your doorstep. Track your order and receive your art with convenience and reliability.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['4']} onChange={() => handleCheck(4)} />
                                            <i></i>
                                            <h2>Explore Artistic Inspiration</h2>
                                            <p>Immerse yourself in the stories and creative processes behind each artwork. Discover the inspiration and passion that brings our diverse collection to life.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['5']} onChange={() => handleCheck(5)} />
                                            <i></i>
                                            <h2>Exceptional Customer Service</h2>
                                            <p>Experience dedicated customer support for any questions or assistance. We're here to ensure your satisfaction and enhance your art buying journey.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </div >
    )
}

export default About