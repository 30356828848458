import React, { useEffect, useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Features = () => {
    const sliderRef = useRef(null);
    useEffect(() => {
        sliderRef.current.slickPlay();
    }, [])

    const settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true,

        // the magic
        responsive: [{
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                infinite: true
            }

        }, {

            breakpoint: 1201,
            settings: {
                slidesToShow: 3,
                dots: true
            }

        }, {

            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                dots: true
            }

        }, {

            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                dots: true
            }

        }, {

            breakpoint: 300,
            settings: {
                slidesToShow: 1,
                dots: true
            },
        }]
    }
    return (
        <>
            <div class="full-features">
                <div class="container">
                    {/* <div class="container d-flex"> */}
                    {/* <div class="row slider"> */}
                    <Slider {...settings} ref={sliderRef}>
                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont-paint"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Exclusive Collections</h3>
                                </div>
                            </div>
                        </div>


                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont-businesswoman"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Artist Profiles</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont-lock"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Secure Payments</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont-world"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Worldwide Shipping</h3>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont icofont-wheat"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Healthy Food</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont icofont-truck-loaded"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>Free Shipping</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont icofont-ui-chat"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>24/07 Support</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                            <div class="features-box text-center">
                                <div class="features-icon-border">
                                    <div class="features-icon">
                                        <i class="icofont icofont-rooster"></i>
                                    </div>
                                </div>
                                <div class="features-text">
                                    <h3>From our farm</h3>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                    {/* </div> */}
                {/* </div> */}
                </div>
            </div>
        </>
    )
};

export default Features;
