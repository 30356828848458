import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Header from './Layout/header'
import Footer from './Layout/footer'
import { homeSelector, settingData } from '../store/reducer/HomeReducer';

const ShippingPolicy = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { settingDatas } = useSelector(homeSelector);    
    useEffect(() => {
        let page = 0;
        let limit = 0;
        let query = "";
        dispatch(settingData({}))
    }, []);
    return (
        <div>
            <Header />
            {/* <div className='pt-4'> */}
            <div class="hero_section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="herosection_content">

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section class="privacy_section">
                <div class="container">
                    <div class="policies">
                        <h3><strong>Shipping Policy for Art Gallery</strong></h3>

                        <p>Thank you for choosing Art Gallery for your fresh and healthy produce needs. Please take a moment to review our shipping policy.</p>

                        <h3><strong>Shipping Method</strong></h3>

                        <p>We currently offer standard shipping for all orders.</p>

                        <h3><strong>Processing Time</strong></h3>

                        <p>Orders are processed in Weekdays (Monday to Friday)</p>

                        <h3><strong>Delivery Time</strong></h3>

                        <p>The delivery time will range from a minimum of 1 day to a maximum of 2 days.</p>

                        <h3><strong>Shipping Charges</strong></h3>

                        <p>Shipping charges are calculated based on the weight of the products and the destination.</p>

                        <h3><strong>Delivery Delays</strong></h3>

                        <p>While we strive to deliver your orders within the specified timeframes, please note that unforeseen circumstances such as weather conditions or carrier delays may affect delivery times. We appreciate your understanding in such situations.</p>

                        <h3><strong>Contact Information</strong></h3>

                        <p>If you have any questions or concerns about your order, please contact our customer service team at [{settingDatas?.gmail}] or call us at [{settingDatas?.contact}].</p>

                        Thank you for choosing Art Gallery!

                    </div>
                </div>
            </section>
            {/* </div> */}
            <Footer />
        </div>
    )
}

export default ShippingPolicy;