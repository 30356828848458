import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import Header from './Layout/header'
import Footer from './Layout/footer'
import { homeSelector, settingData } from '../store/reducer/HomeReducer';

const ReturnProduct = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { settingDatas } = useSelector(homeSelector);
  useEffect(() => {
        let page = 0;
        let limit = 0;
        let query = "";       
        dispatch(settingData({}))
    }, []);
    return (
        <div>
            <Header />
            {/* <div className='pt-4'> */}
            <div class="hero_section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="herosection_content">

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section class="privacy_section">
                <div class="container">
                    <div class="policies">
                        <h3><strong>Return Policy Art Gallery</strong></h3>

                        <p>At Art Gallery, we prioritize the quality and freshness of our produce to ensure your satisfaction. If, however, you encounter any issues with your order, our return policy is designed to address your concerns seamlessly.</p>

                        <p>If you have additional questions or require more information about our Return Policy, do not hesitate to contact us.</p>

                        <h3><strong>No Questions Asked Return at Delivery</strong></h3>

                        <p>If, upon delivery, you find any item unsatisfactory or not up to your expectations, we offer a "no questions asked" return policy. Simply inform our delivery personnel, and we will take back the product immediately. </p>

                        <h3><strong>Refund and Credit Note</strong></h3>

                        <p>Once the product is returned, we'll issue a prompt refund or provide a credit note equivalent to the product's value. This credit can be utilized for future purchases on the Art Gallery platform.</p>

                        <h3><strong>Post-Delivery Returns & Refunds</strong></h3>

                        <p>For concerns identified after the delivery, please contact our customer service team at  [{settingDatas?.gmail}] or call us at [{settingDatas?.contact}]. Our team will guide you through the return process.</p>                      

                        <h3><strong>Inspection and Quality Assurance</strong></h3>

                        <p>Acceptance of post-delivery returns is subject to a thorough inspection by our quality assurance team. We strive to address your concerns efficiently and maintain the highest standards of freshness and quality.</p>

                        <h3><strong>Covid-19 Safety Measures</strong></h3>

                        <p>In adherence to Covid-19 safety protocols, we do not pick up goods post-delivery. We encourage all customers to inspect their orders thoroughly at the time of delivery.</p>

                    </div>
                </div>
            </section>
            {/* </div> */}
            <Footer />
        </div>
    )
}

export default ReturnProduct;