import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import SigninReducer from "./reducer/SigninReducer";
import ProductReducer from "./reducer/ProductReducer";
import HomeReducer from "./reducer/HomeReducer";
import CartReducer from "./reducer/CartReducer";

// import logger from "redux-logger";
export const reducer = {
  signInConfiguration: SigninReducer,
  ProductReducer: ProductReducer,
  HomeReducer: HomeReducer,
  CartReducer: CartReducer,
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
